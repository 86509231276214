<script setup lang="ts">
import type { BackgroundColorsTypes } from '@ocp-zmarta/zmarta-components'
import { getServerState, useRoute, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { ref, defineAsyncComponent, onBeforeUnmount } from 'vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      isElskling: getServerState('isElskling'),
    }

// Components
const ZgBorder = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgBorder))
const backgroundColor = ref<BackgroundColorsTypes | undefined>()

const route = useRoute()
const bgColor = route?.searchParams.get('bg-color') as BackgroundColorsTypes

const setBackgroundColor = () => {
  if (bgColor) {
    backgroundColor.value = bgColor
    return
  }

  if (typeof document === 'undefined') return

  const lastSection = document.querySelector('main > section:last-child > div:first-child')

  if (!lastSection) return

  const computedStyles = getComputedStyle(lastSection)
  const bgColorComputed = computedStyles?.backgroundColor

  if (!bgColorComputed) return

  backgroundColor.value = ['rgb(255, 255, 255)', 'rgba(0, 0, 0, 0)'].includes(bgColorComputed)
    ? 'white'
    : 'beige'
}

let timeoutId: ReturnType<typeof setTimeout> | null = null

if (import.meta.env.SSR) {
  setBackgroundColor()
} else {
  timeoutId = setTimeout(() => {
    setBackgroundColor()
  }, 300)
}

onBeforeUnmount(() => {
  if (timeoutId !== null) {
    clearTimeout(timeoutId)
  }
})
</script>

<template>
  <div class="border">
    <div
      :class="[
        'spacer',
        {
          'spacer--white': backgroundColor === 'white',
          'spacer--beige': backgroundColor === 'beige',
        },
      ]"
    />

    <zg-border
      :border="ctx?.isElskling ? 'clouds' : 'footer'"
      :background-color="backgroundColor"
      border-color="brand-100"
    />
  </div>
</template>

<style lang="scss" scoped>
  .border {
    .spacer {
      height: rhythm(double);

      &--white {
        background-color: var(--white);
      }

      &--beige {
        background-color: var(--beige);
      }
    }
  }
</style>
